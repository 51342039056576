<template>
    <v-container fluid class="white py-3 px-2 shadow1 rounded">
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getProducts">
                    <v-row style="align-items: center;">
                        <v-col cols="auto" sm="2">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="3">Type</v-col>
                                <v-col cols="9">
                                    <v-combobox v-model="searchType" dense outlined hide-details :items="['All', 'By Category', 'By Brand']">
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto" sm="3" v-if="searchType == 'By Category'">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Cateogry</v-col>
                                <v-col cols="8">
                                    <v-combobox dense outlined hide-details v-model="category" @focus="$store.dispatch('category/getCategories')" :items="$store.getters['category/categories']" :loading="$store.getters['category/loadingCategories']" item-text="name" item-value="id">
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto" sm="3" v-if="searchType == 'By Brand'">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Brand</v-col>
                                <v-col cols="8">
                                    <v-combobox dense outlined hide-details v-model="brand" @focus="$store.dispatch('brand/getBrands')" :items="$store.getters['brand/brands']" :loading="$store.getters['category/loadingBrands']" item-text="name" item-value="id">
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto" sm="1">
                            <v-btn type="submit" class="text_bg_fave" :loading="loading">Search</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-btn @click="print" v-if="$store.getters['product/products'].length">Print</v-btn>
                </v-form>
            </v-col>
        </v-row>

        <v-row no-gutters class="py-2" v-if="show">
            <v-col cols="12">
                <v-simple-table class="bordered-table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Sl</th>
                                <th>Product Id</th>
                                <th>Product Name</th>
                                <th>Category</th>
                                <th>Brand</th>
                                <th>Purchase Rate</th>
                                <th>Sale Rate</th>
                                <th>Wholesale Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in $store.getters['product/products']" :key="product.sl">
                                <td class="text-center">{{ product.sl }}</td>
                                <td class="text-center">{{ product.code }}</td>
                                <td class="text-center">{{ product.name }}</td>
                                <td class="text-center">{{ product.category.name }}</td>
                                <td class="text-center">{{ product.brand.name }}</td>
                                <td class="text-center">{{ product.purchase_rate }}</td>
                                <td class="text-center">{{ product.sale_rate }}</td>
                                <td class="text-center">{{ product.wholesale_rate }}</td>
                            </tr>
                            <tr v-if="$store.getters['product/products'].length == 0">
                                <td colspan="9" class="text-center">Poduct information is not available</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <div v-else class="no_result">
            <div class="img"></div>
        </div>
        <div id="printTable" style="display: none;">
            <table class="bordered-table">
                <tr>
                    <th>Sl</th>
                    <th>Product Id</th>
                    <th>Product Name</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Purchase Rate</th>
                    <th>Sale Rate</th>
                    <th>Wholesale Rate</th>
                </tr>
                <tr v-for="product in $store.getters['product/products']" :key="product.sl">
                    <td class="text-center">{{ product.sl }}</td>
                    <td class="text-center">{{ product.code }}</td>
                    <td class="text-center">{{ product.name }}</td>
                    <td class="text-center">{{ product.category.name }}</td>
                    <td class="text-center">{{ product.brand.name }}</td>
                    <td class="text-center">{{ product.purchase_rate }}</td>
                    <td class="text-center">{{ product.sale_rate }}</td>
                    <td class="text-center">{{ product.wholesale_rate }}</td>
                </tr>
            </table>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'Products',

    data: () => ({
        searchType: 'All',
        category: null,
        brand: null,
        loading: false,
        show: false
    }),

    methods: {
        async getProducts() {
            this.loading = true;

            if (this.searchType == 'All') {
                this.category = null;
                this.brand = null;
            }
            if (this.searchType != 'By Category') {
                this.category = null;
            }
            if (this.searchType != 'By Brand') {
                this.brand = null;
            }

            let filter = {
                categoryId: this.category == null ? null : this.category.id,
                brandId: this.brand == null ? null : this.brand.id
            }

            await this.$store.dispatch('product/getProducts', filter)
            this.show = true;
            this.loading = false;
        },

        async print() {
            let invoiceContent = document.querySelector('#printTable').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let companyProfile = this.$store.getters['companyProfile/company']
            let companyImage = companyProfile != null ? this.$store.getters['companyProfile/company'].image : ''
            let ImagePath = companyImage ? this.apiBasePath + '/' + companyImage : '';
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                    ${this.setStyle()}
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ ImagePath + `" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyProfile != null ? companyProfile.company_name : ''}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${companyProfile != null ? companyProfile.address : ''}</p>
                                                    <p style="margin:0px">Mobile : ${companyProfile != null ? companyProfile.contact_us : ''}</p>
                                                    <p style="margin:0px">E-Mail : ${companyProfile != null ? companyProfile.email : ''}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Product list
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);

            printWindow.moveTo(0, 0);

            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle() {
            return `
            body {
                font-family: Arial,sans-serif;
            }
            .bordered-table {
  border-collapse: collapse;
  width: 100%;
}

.bordered-table th,
.bordered-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.bordered-table thead th {
  background-color: #f2f2f2;
}

.bordered-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
        },
    }
}
</script>

<style lang="scss" scoped>
.v-icon.v-icon {
    font-size: 18px !important;
    top: -3px !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    font-size: 11px;
    // padding: 0px 1px;
    background: var(--theme_lighter);
    // color: #fff;
    // border: 1px solid #ccc;
    text-align: center;
    // height: 24px !important;
}

.v-data-table--dense>.v-data-table__wrapper>table>tbody>tr>td {
    // height: 20px !important;
    // padding: 0px 5px !important;
    // font-size: 12px !important;
    border: 1px solid #ccc !important;
}
</style>